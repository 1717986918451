/* eslint-disable class-methods-use-this */

import { UserData } from '@core/users/users';
import { WellnessCheckValues } from '@core/wellness';
import { Community, TMUserFromAPI } from '@web/features/auth/user';
import { Meditation } from '@core/meditations';
import { Resource } from '@core/resources';
import { Hope } from '@core/hopes';
import { MessageInABottle } from '@core/message-in-a-bottle';
import { Recording } from './recording';
import { HopeQuery } from './queries';
import { WellnessCheckValuesWithCreation } from './wellness';
import { CodedError } from './error';

type userDataListener = (_: UserData) => void;

type apiCtx = {
  userDataListeners: userDataListener[];
};

const context = { userDataListeners: [] } as apiCtx;

export const knownErrors: Record<string, string> = {
  incorrect_password:
    "Incorrect password. You can reset your password if you don't remember it.",
  email_not_found:
    'Your email address is not registered. Please create an account, if you want to join us.',
  entity_not_found: 'Not found',
  community_not_found:
    "Couldn't find Community of Care. If you don't have a magic code, you can join Time Machine as a normal user or be invited into a community later.",
};

export class TMApi {
  public static onUserDataUpdate(fn: userDataListener) {
    context.userDataListeners.push(fn);
  }

  private static getApiBase(): string {
    return (
      process.env.NEXT_PUBLIC_TMAPI_BASE || `//${window.location.hostname}:8080`
    );
  }

  static async signIn(
    username: string,
    password: string,
    shouldRemember = false,
  ) {
    const response = await fetch(TMApi.getApiBase() + '/auth', {
      method: 'POST',
      body: JSON.stringify({
        username,
        password,
      }),
    });

    if (response.status !== 200) {
      const err = await TMApi.parseError(response);
      throw err;
    }
    return response.json().then((obj) => {
      // TODO: handle null token
      TMApi.setToken(obj.token, shouldRemember);
    });
  }

  static async parseError(resp: Response): Promise<Error> {
    try {
      const json = await resp.json();
      if ('code' in json) {
        return (
          new CodedError(knownErrors[json.code], json.code) ||
          new Error(json.error as string)
        );
      }
      return new Error(json.error as string);
    } catch {
      try {
        const text = await resp.text();
        return new Error(text);
      } catch {
        return new Error('Internal error');
      }
    }
  }

  // restoreLogin checks if there is a login session and restores the session
  static async restoreLogin(handleAuthStateChange: CallableFunction) {
    const token = TMApi.getToken();
    if (token == null || token === '') {
      handleAuthStateChange(null);
      return null;
    }
    return fetch(TMApi.getApiBase() + '/me', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // token is probably toast, so let's reset it:
          TMApi.deleteToken();

          handleAuthStateChange(null);
          return null;
        }
        return response.json().then((obj) => {
          const user = TMUserFromAPI(obj);
          handleAuthStateChange(user);
          return user;
        });
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  getUserDocument(): Promise<UserData> {
    return this.call('/me/document', 'GET');
  }

  static getToken(): string | null {
    const tokenPrefix = 'sess';
    const url = new URL(window.location.href);
    let token = url.searchParams.get('token');
    if (token != null && token.startsWith(tokenPrefix)) {
      TMApi.setToken(token, true);
      window.location.href = '/'; // redirect to remove token from URL
    }

    token = window.localStorage.getItem('tmapi_token');
    if (token == null) {
      token = window.sessionStorage.getItem('tmapi_token');
    }

    if (token != null && !token?.startsWith(tokenPrefix)) {
      console.log(
        `Token does not start with ${tokenPrefix}, which means that it is corrupt or invalid, let's ignore it.`,
      );
      TMApi.deleteToken();
      return null;
    }
    return token;
  }

  static setToken(token: string, longlivedSession: boolean) {
    const storage = longlivedSession
      ? window.localStorage
      : window.sessionStorage;
    storage.setItem('tmapi_token', token);
  }

  static deleteToken() {
    window.sessionStorage.removeItem('tmapi_token');
    window.localStorage.removeItem('tmapi_token');
  }

  static getHeaders(): Record<string, string> {
    const token = TMApi.getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (!token) {
      return headers;
    }
    return { ...headers, Authorization: `Bearer ${token}` };
  }

  addWellness(wellness: WellnessCheckValues): Promise<any> {
    return this.call('/wellness', 'POST', wellness);
  }

  getWellnessChecks(): Promise<WellnessCheckValuesWithCreation[]> {
    return this.call('/wellness', 'GET').then((resp) => {
      return resp.wellness_checks;
    });
  }

  updateUserDocument(update: Partial<UserData>): Promise<any> {
    return this.call('/me/document', 'PATCH', update).then((updatedDoc) => {
      context.userDataListeners.forEach((fn) => {
        fn(updatedDoc);
      });
    });
  }

  async call(path: string, method: string, data?: any): Promise<any> {
    const req: RequestInit = {
      method,
      headers: TMApi.getHeaders(),
    };
    let reqPath = path;
    if (data && method !== 'GET') {
      req.body = JSON.stringify(data);
    } else if (data && method === 'GET') {
      reqPath += '?' + new URLSearchParams(data).toString();
    }

    const response = await fetch(TMApi.getApiBase() + reqPath, req);

    if (response.status >= 400) {
      throw await TMApi.parseError(response);
    }
    if (response.status === 204) {
      return null;
    }
    return response.json().then((obj) => {
      return obj;
    });
  }

  getRecording(id: string): Promise<Recording> {
    return this.call('/recordings/' + id, 'GET');
  }

  getRecordings(): Promise<Recording[]> {
    return this.call('/recordings', 'GET').then((response) => {
      return response['recordings'];
    });
  }

  updateRecording(id: string, update: Partial<Recording>) {
    return this.call('/recordings/' + id, 'PATCH', update);
  }

  createUploadTicket(collection: string): Promise<string> {
    return this.call('/upload-ticket/' + collection, 'POST').then(
      (response) => {
        return response['upload_url'];
      },
    );
  }

  uploadRecording(uploadURL: string, file: File, meta: any): Promise<string> {
    // uploadURL assumes a URL that can be directly used for uploading (e.g. presigned S3 URL)
    return fetch(uploadURL, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    }).then((_) => {
      meta['upload_url'] = uploadURL;
      return this.call('/recordings', 'POST', meta).then((response) => {
        return response['recording_id'];
      });
    });
  }

  getMeditations(type: string, category?: string): Promise<Meditation[]> {
    let q: { type: string; category?: string } = { type: type };
    if (category != null) {
      q.category = category;
    }
    return this.call('/meditations?' + new URLSearchParams(q), 'GET').then(
      (response) => {
        return response['meditations'];
      },
    );
  }

  getMeditationById(id: string): Promise<Meditation> {
    return this.call('/meditations/' + id, 'GET').then((response) => {
      return response['meditation'];
    });
  }

  uploadMeditation(uploadURL: string, file: File, meta: any): Promise<string> {
    return this.uploadFileAndPost(uploadURL, '/meditations', file, meta).then(
      (resp) => {
        return resp['meditation_id'];
      },
    );
  }

  uploadMeditationBackground(file: File): Promise<string> {
    return this.createUploadTicket('meditation_backgrounds').then(
      (uploadURL) => {
        return this.upload(uploadURL, file).then((_) => {
          return uploadURL;
        });
      },
    );
  }

  deleteMeditation(id: string) {
    return this.call('/meditations/' + id, 'DELETE');
  }

  uploadFileAndPost(
    uploadURL: string,
    postPath: string,
    file: File,
    meta: any,
  ): Promise<any> {
    return this.upload(uploadURL, file).then((_) => {
      meta['upload_url'] = uploadURL;
      return this.call(postPath, 'POST', meta).then((response) => {
        return response;
      });
    });
  }

  upload(uploadURL: string, file: File): Promise<Response> {
    return fetch(uploadURL, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });
  }

  getResources(query: any): Promise<Resource[]> {
    return this.call('/resources', 'GET', query).then((response) => {
      return response['resources'];
    });
  }

  async addResource(name: string): Promise<Resource> {
    const r = await this.call('/resources', 'POST', { name });
    return r.resource;
  }

  getHopes(query: HopeQuery): Promise<Hope[]> {
    return this.call('/hopes', 'GET', query).then((response) => {
      return response['hopes'];
    });
  }

  getHopeCount(query: HopeQuery): Promise<number> {
    return this.call('/hopes', 'GET', query).then((response) => {
      return response['page']['count'];
    });
  }

  getHope(hopeID: string): Promise<Hope> {
    return this.call('/hopes/' + hopeID, 'GET').then((response) => {
      return response['hope'];
    });
  }

  updateHope(hopeID: string, update: Partial<Hope>): Promise<Hope> {
    return this.call('/hopes/' + hopeID, 'PATCH', update).then((response) => {
      return response['hope'];
    });
  }

  addHope(hope: Hope): Promise<Hope> {
    return this.call('/hopes', 'POST', hope).then((response) => {
      return response['hope'];
    });
  }

  deleteHope(hopeID: string): Promise<any> {
    return this.call('/hopes/' + hopeID, 'DELETE').then((_) => {
      return;
    });
  }

  reportHope(hopeID: string): Promise<any> {
    return this.call('/hopes/' + hopeID + '/reports', 'POST').then((_) => {
      return;
    });
  }

  async muteHope(hopeID: string) {
    await this.call(`/hope-mutes/${hopeID}`, 'POST');
  }

  async muteUser(userID: string) {
    await this.call(`/user-mutes/${userID}`, 'POST');
  }

  getWaterCount(): Promise<number> {
    return this.call('/water', 'GET').then((response) => {
      return response.count;
    });
  }

  addWater(): Promise<number> {
    return this.call('/water', 'POST').then((response) => {
      return response.count;
    });
  }

  resetPassword(email: string): Promise<void> {
    return this.call('/password-reset', 'POST', { email });
  }

  setPassword(token: string, newPassword: string): Promise<void> {
    return this.call('/password', 'POST', {
      token,
      password: newPassword,
    });
  }

  async createUserAccount(
    email: string,
    password: string,
    utm?: Record<string, string>,
    setToken = true,
    magic?: string,
  ): Promise<string> {
    try {
      const response = await this.call('/account', 'POST', {
        email,
        password,
        utm,
        magic,
      });
      if (setToken) {
        TMApi.setToken(response.token, false);
      }
      return response.token;
    } catch (error) {
      if (error instanceof CodedError) {
        if (error.code === 'incorrect_password') {
          throw Error(
            "An account with this email account exists, but your password is incorrect. If you don't remember it, please use the password reset option.",
          );
        }
      }
      throw error;
    }
  }

  confirmEmail(token: string): Promise<any> {
    return this.call('/account/email-confirmation', 'POST', {
      token,
    });
  }

  async logout() {
    const token = TMApi.getToken();
    if (token) {
      await this.call('/sessions', 'DELETE');
    }
    TMApi.deleteToken();
  }

  sendSupportMessage(msg: string, email: string): Promise<void> {
    return this.call('/email', 'POST', { message: msg, email });
  }

  setNewsletterSubStatus(status: string): Promise<void> {
    return this.call('/newsletter', 'POST', { status });
  }

  getMessageInABottle(id: string): Promise<MessageInABottle> {
    return this.call(`/bottle/${id}`, 'GET');
  }

  shareMessageInABottle(recordingID: string): Promise<MessageInABottle> {
    return this.call(`/bottle`, 'POST', { recordingID });
  }

  sendUTMPoint(url: string, utm?: Record<string, string>, ref?: string) {
    return this.call('/utm', 'POST', {
      url,
      utm,
      ref,
    });
  }

  requestAccountDeletion(): Promise<void> {
    return this.call('/account', 'DELETE');
  }

  saveActivity(activity: any): Promise<void> {
    return this.call('/activities', 'POST', activity);
  }

  async registerNotificationToken(token: string, system: string) {
    await this.call('/notifications/tokens', 'POST', { token, system });
  }

  async getCommunityByMagic(
    magic: string,
  ): Promise<{ name: string; id: string }> {
    return this.call('/communities', 'GET', { magic });
  }

  async joinCommunity(magic: string): Promise<Community> {
    return this.call('/community-membership', 'POST', { magic });
  }
}
