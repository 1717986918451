import { Global, ThemeProvider as EThemeProvider } from '@emotion/react';
import * as React from 'react';
import { theme } from './config';
import { globalStyles } from './global.styles';

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  return (
    <EThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      {children}
    </EThemeProvider>
  );
}
