/* eslint-disable no-console */
import { AppProps } from 'next/app';

import { AuthProvider } from '@web/features/auth/auth.context';
import { ThemeProvider } from '@web/features/theme';
import { useServiceWorker } from '@web/features/use-service-worker';
import { UserProvider } from '@web/features/users/user.context';

import '@web/components/calendar/calendar.css';
import '@web/features/theme/fonts.css';
import useAppBridge from '@web/components/app-bridge';
import { useEffect, useState } from 'react';

/**
 * # App
 *
 * A wrapper for our client-side app.
 * Implemented with Next.JS, this is our custom verison.
 *
 * - Installs and registers the service-worker from the service worker.
 * - Houses our {@link ThemeProvider} for custom theming
 * - Open and maintains a listener for authentication from our {@link AuthProvider}
 * - Opens and maintains a listener for `userData` from our {@link UserProvider}
 */
function App({ Component, pageProps }: AppProps) {
  useServiceWorker();
  const { notifyAppLoaded } = useAppBridge();
  const [notified, setNotified] = useState(false);

  useEffect(() => {
    if (!notified) notifyAppLoaded();
    setNotified(true);
  }, [notified, notifyAppLoaded]);

  return (
    <ThemeProvider>
      <AuthProvider>
        <UserProvider>
          <Component {...pageProps} />
        </UserProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
