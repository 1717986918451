import { css } from '@emotion/react';
import { theme } from '@web/features/theme';

export const classNames = {
  popup: { default: 'default-popup', toast: 'colored-toast' },
  icon: { noBorder: 'no-border' },
  actions: { stacked: 'stacked--actions' },
  confirm: { stacked: 'stacked--confirm' },
  cancel: { stacked: 'stacked--cancel' },
  deny: { stacked: 'stacked--deny' },
};

export const defaultClassNames = {
  popup: classNames.popup.default,
  icon: classNames.icon.noBorder,
  actions: classNames.actions.stacked,
  confirmButton: classNames.confirm.stacked,
  cancelButton: classNames.cancel.stacked,
  denyButton: classNames.deny.stacked,
};

export const swalStyles = css`
  // icons
  .swal2-popup .swal2-icon {
    &-success {
      color: ${theme.colors.success} !important;
      border-color: ${theme.colors.success} !important;
    }

    &-error {
      color: ${theme.colors.error} !important;
      border-color: ${theme.colors.error} !important;
    }

    &-warning {
      color: ${theme.colors.warning} !important;
      border-color: ${theme.colors.warning} !important;
    }

    &-info {
      color: ${theme.colors.info} !important;
      border-color: ${theme.colors.info} !important;
    }

    &-question {
      color: ${theme.colors.info} !important;
      border-color: ${theme.colors.info} !important;
    }
  }

  .swal2-container.swal2-backdrop-show {
    background-color: ${theme.colors.blackAlpha['800']};
  }

  // default popups
  .swal2-popup.swal2-show.default-popup {
    padding: ${theme.space[6]} 0;
    box-shadow: ${theme.shadows.xl};
    position: relative;
    border-radius: ${theme.radii.xl};
    background: ${theme.colors.whiteAlpha[900]};

    &.full-image {
      padding: 0%;
      background: transparent;
      max-width: ${theme.breakpoints[0]};

      & > div:not(.swal2-image) {
        background: ${theme.colors.whiteAlpha[900]};
      }

      @supports (margin: -10%) {
        & .swal2-title {
          background-color: ${theme.colors.whiteAlpha[900]};
        }
      }

      .swal2-actions {
        border-bottom-right-radius: ${theme.radii.xl};
        border-bottom-left-radius: ${theme.radii.xl};
      }

      .swal2-timer-progress-bar-container {
        display: none;
      }
    }

    .swal2-image {
      max-height: ${theme.space[32]};
      margin: 0 auto;
      object-fit: contain;
      object-position: center;

      &.full-image {
        display: block;
        max-height: 100% !important;
        width: 100% !important;
      }
    }

    .swal2-icon {
      svg {
        height: ${theme.space[16]};
        width: ${theme.space[16]};
      }
    }

    .swal2-input {
      width: 100%;
      margin: 1% auto;
    }

    .swal2-html-container {
      width: 100%;
      padding: 2% 5%;
      margin: 0 auto;
      text-align: left;

      p {
        line-height: ${theme.lineHeights.tall};
      }
    }

    .swal2-title {
      letter-spacing: ${theme.letterSpacings.normal};
      padding: 1% 5%;
      color: ${theme.colors.info};
      text-align: left;

      p {
        font-size: ${theme.fontSizes['3xl']};
      }
    }

    .no-border {
      border: none !important;
    }

    .stacked--actions {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      width: 100%;
      padding: 2% 0;

      button {
        border-radius: ${theme.radii.full};
        padding: ${theme.space[3]} ${theme.space[8]};
        display: block;
        width: 50%;
      }

      .stacked--cancel,
      .stacked--deny {
        background-color: transparent;
        color: ${theme.colors.info};
        font-weight: 600;
      }

      .stacked--deny {
        border: ${theme.borders['2px']} ${theme.colors.info};
      }

      .ghost {
        border: none;
        background: transparent;
      }
    }
  }

  // toasts
  .swal2-popup.colored-toast {
  }
`;
