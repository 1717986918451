export const MIN_DAYS_FOR_F2 = isProduction() ? 3 : 2;
export const MIN_DAYS_FOR_F3 = isProduction() ? 6 : 3;

/**
 * # isClientSide
 */
export function isClientSide() {
  return typeof window !== 'undefined' && typeof document !== 'undefined';
}

/**
 * # isProduction
 *
 * Determine the current env by analysing the app_name in
 *  the local .env file, vercel env variables, etc
 *
 */
export function isProduction() {
  return process.env.NEXT_PUBLIC_APP_ENV === 'production';
}

/**
 * Determines a subset of features internally known as "Time Travelling" to allow
 * easier debugging and scenario mapping
 *
 * This feature should NEVER be available on a user-facing site such as
 * - staging.timeachine.love
 *
 *
 * @param name - optional name to test against
 */
export function isTimeTravelAvailable(
  name = process.env.NEXT_PUBLIC_APP_NAME ?? 'dev',
) {
  return /(test|dev|preview)/gi.test(name);
}

/**
 * # isEmulated
 *
 * Determine whether the current firebase instance should be run on the emulated environment
 * Targeting local development, CI testing, but not staging, previews, or production
 *
 * @note ~~Firebase Emulators does NOT support storage.
 *  Be sure to safe guard anything in storage with something from the `test`
 *  folder in the current firebase env for access~~ Fixed Sept 2021 - @tkjohnson121

 */
export function isEmulated() {
  // return false; // <= Uncomment to disable emulated mode

  return (
    typeof window !== 'undefined' &&
    window.location.hostname === 'localhost' &&
    process.env.NEXT_PUBLIC_APP_ENV !== 'production'
  );
}

export function getPlatform() {
  return isClientSide() && 'navigator' in window ? navigator?.platform : null;
}
export function getUserAgent() {
  return isClientSide() && 'navigator' in window ? navigator?.userAgent : null;
}

/**
 * getUserDevice
 *
 * Get and return the user's current devices (IOS, ANDROID, WEB)
 *
 *
 * @see https://stackoverflow.com/questions/19877924/what-is-the-list-of-possible-values-for-navigator-platform-as-of-today
 *
 * @return 'ANDROID' | 'IOS' | 'WEB' | null
 */
export function getDevice() {
  if (getPlatform()) {
    const { userAgent, platform } = navigator;
    if (/(android|linux)/gi.test(userAgent)) {
      return 'ANDROID';
    }
    if (
      /iPhone/gi.test(platform) ||
      /iPod/gi.test(platform) ||
      /iPad/gi.test(platform) ||
      /iPhone Simulator/gi.test(platform) ||
      /iPod Simulator/gi.test(platform) ||
      /iPad Simulator/gi.test(platform) ||
      /Macintosh/gi.test(platform) ||
      /MacIntel/gi.test(platform) ||
      /MacPPC/gi.test(platform) ||
      /Mac68K/gi.test(platform) ||
      /Pike v7.6 release 92/gi.test(platform) ||
      /Pike v7.8 release 517/gi.test(platform)
    ) {
      return 'IOS';
    }

    return 'WEB';
  }
  return null;
}

export const DONATE_LINK =
  'https://loveandtime.org/donate/';
