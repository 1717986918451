import { UserData } from '@core/users/users';
import { AuthStatus } from '@web/features/auth/auth.state';
import { useAuth } from '@web/features/auth/use-auth';
import * as React from 'react';
import { TMApi } from '@web/services/tmapi';
import {
  initialUserState,
  userDataReducer,
  UserDispatchTypes,
  UserPayloads,
  UserState,
} from './user.state';

export const UserStateContext =
  React.createContext<UserState>(initialUserState);

export const UserDispatchContext = React.createContext<React.Dispatch<{
  type: UserDispatchTypes;
  payload: UserPayloads;
}> | null>(null);

/**
 * # UserProvider
 *
 * @remarks
 * Responsible for managing `userData` state across the entire application.
 * Located in {@link _app.tsx}, this component will provide global `userData`
 *  state.
 *
 * It works be enabling the firebase {@link db.ts} `onSnapshot` listener to fetch
 *  and listen to updates in a user's document
 *
 * Use hook {@link useUserData} to access it's state and methods.
 *
 * @param overrideUid - used to `loginAsUser` for admin user's
 *
 * @note depends on {@link useAuth} to function
 */

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [{ status: authStatus, isAuthenticated }] = useAuth();
  const [state, dispatch] = React.useReducer(userDataReducer, {
    ...initialUserState,
  });

  const tmapi = new TMApi();

  const onUserSnapshot = (snapshot: UserData) =>
    snapshot
      ? dispatch({
          type: UserDispatchTypes.ON_USER_FULFILLED,
          payload: { userData: snapshot },
        })
      : dispatch({
          type: UserDispatchTypes.ON_USER_ERROR,
          payload: { error: new Error('No User Data found.') },
        });

  React.useEffect(() => {
    let unsubscribe: () => void;

    const fetchUserDocument = async () => {
      const isSignedIn = authStatus === AuthStatus.FULFILLED && isAuthenticated;

      if (isSignedIn && state.retries < 5) {
        dispatch({ type: UserDispatchTypes.ON_USER_FETCH });

        await tmapi.getUserDocument().then((doc) => {
          onUserSnapshot(doc);
        });

        TMApi.onUserDataUpdate(onUserSnapshot);
      }
    };
    fetchUserDocument();

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [
    authStatus,
    isAuthenticated,
    state.overrideUid,
    state.error,
    state.retries,
  ]);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}
