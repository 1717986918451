import { differenceInCalendarDays, isSameDay } from 'date-fns';
import { addDays } from '../utils/dates';
import { MIN_DAYS_FOR_F2, MIN_DAYS_FOR_F3 } from '../utils/env';
import { UserData, UserStatus } from './users';

export function userStatusReducer(
  userData: UserData | { before?: UserData; after: UserData },
  isServerSide = false,
): UserStatus {
  const now = new Date().getTime();
  const {
    mturk_id,
    last_active,
    last_wellness_check,
    has_onboarded,
    f1_rolling_days,
    f1_next_morning_session,
    f1_status,
    f1_last_recording,
    f2_unlock_date,
    f2_last_listen,
    f3_last_watering,
  } = 'after' in userData ? userData.after : userData;

  /** Is considered a demo account, has special priveleges like advancing features faster */
  const isDemo = mturk_id === 'DEMO';

  /**
   *
   * # lastActiveTime
   *
   * client-side
   * A user's closest active time stored on the user document
   *
   * server-side
   * Getting a user's status depending on the current time.
   */
  const lastActiveTime = isServerSide ? now : new Date(last_active).getTime();

  // A user's last hope + 1day
  // Used to determine if a user should complete their wellness check
  const lastWellnessAndADay = last_wellness_check
    ? +addDays(1, last_wellness_check)
    : null;

  const shouldWellness = lastWellnessAndADay
    ? // if it's been 24hrs since the last wellness check,
      lastWellnessAndADay < lastActiveTime
    : //  or if the attribute is missing from a user's document.
      true;

  /** F1 - Time Machine */

  // A user's next morning session converted to a numerical value
  const nextMorningSessionTime = f1_next_morning_session
    ? new Date(f1_next_morning_session).getTime()
    : null;

  // A user's morning session + 1day
  // Used to determine if a user has missed a full day of activity
  const morningSessionAndADay = f1_next_morning_session
    ? +addDays(1, f1_next_morning_session)
    : null;

  // A user has missed a full day of activity.
  const hasMissedASession = morningSessionAndADay
    ? lastActiveTime >= morningSessionAndADay
    : false;

  // A user's morning session is now available to them
  let hasPassedMorningSession = nextMorningSessionTime
    ? lastActiveTime >= nextMorningSessionTime
    : false;

  if (isDemo) {
    hasPassedMorningSession = true; // demo users can always listen/record
  }

  // A user has recorded a message before
  const hasRecorded = !!f1_last_recording;

  /**
   * User has completed all of their activities for the day,
   * OR
   * Status has been reset for lack of activity in a 24 hour period.
   */
  const isFlowComplete =
    f1_status === 'FLOW_COMPLETE' && !hasPassedMorningSession;

  const shouldOnboard = !has_onboarded;

  const shouldListen =
    hasRecorded &&
    // in the current morning session or after having missed a session
    (hasPassedMorningSession || hasMissedASession) &&
    // just completed the wellness check or missed it
    (f1_status === 'FLOW_START' || f1_status === 'FLOW_COMPLETE');

  const shouldRecord =
    // just finished onboarding
    (!hasRecorded && f1_status === 'FLOW_START') ||
    (!shouldListen &&
      // in current morning session
      hasPassedMorningSession &&
      // has not already recorded a message today
      f1_status === 'FLOW_1');


  const hasHadConsecutiveDays =
    f1_rolling_days % 5 === 0 && hasPassedMorningSession && !hasMissedASession;

  // determine the number of days since a user's last time-machine message
  const f1Difference =
    nextMorningSessionTime &&
    lastActiveTime &&
    differenceInCalendarDays(lastActiveTime, nextMorningSessionTime);

  // determine if today is an interval of 5 days since a user's last
  //  time-machine activity
  const hasMissedDays =
    f1Difference && f1Difference > 1 ? f1Difference % 5 === 0 : false;

  /** F2 - Meditations */
  let hasUnlockedF2 = Boolean(f1_rolling_days >= MIN_DAYS_FOR_F2);

  if (isDemo) hasUnlockedF2 = Boolean(f1_rolling_days >= 1); // demo users can unlock after a shorter period

  const lastMeditation = f2_last_listen;
  const lastMeditationAndAWeek = lastMeditation
    ? +addDays(7, lastMeditation)
    : null;

  const hasMissedMeditations =
    lastMeditationAndAWeek && lastActiveTime
      ? hasPassedMorningSession &&
        hasUnlockedF2 &&
        isSameDay(lastActiveTime, lastMeditationAndAWeek) &&
        lastMeditationAndAWeek > lastActiveTime
      : false;

  const f2UnlockDate = f2_unlock_date;

  const f2UnlockDateDifference =
    f2UnlockDate &&
    lastActiveTime &&
    differenceInCalendarDays(new Date(f2UnlockDate), lastActiveTime);

  const hasNotStartedMeditations =
    f2UnlockDateDifference && f2UnlockDateDifference <= 12
      ? f2UnlockDateDifference % 3 === 0
      : false;

  /** F3  - Hopes */
  let hasUnlockedF3 = Boolean(f1_rolling_days >= MIN_DAYS_FOR_F3);

  if (isDemo) hasUnlockedF3 = Boolean(f1_rolling_days >= 2); // demo users can unlock after a shorter period

  const f3CanWater =
    hasPassedMorningSession &&
    (!f3_last_watering ||
      !isSameDay(new Date(f3_last_watering), lastActiveTime));

  const hasSnapshots = 'after' in userData && 'before' in userData;

  let isUnlockingF2 =
    hasSnapshots &&
    (userData.before?.f1_rolling_days || MIN_DAYS_FOR_F2) < MIN_DAYS_FOR_F2 &&
    userData.after.f1_rolling_days >= MIN_DAYS_FOR_F2;

  let isUnlockingF3 =
    hasSnapshots &&
    (userData.before?.f1_rolling_days || MIN_DAYS_FOR_F3) < MIN_DAYS_FOR_F3 &&
    userData.after.f1_rolling_days >= MIN_DAYS_FOR_F3;

  if (isDemo) {
    isUnlockingF2 =
      hasSnapshots &&
      (userData.before?.f1_rolling_days || 1) < 1 &&
      userData.after.f1_rolling_days >= 1;

    isUnlockingF3 =
      hasSnapshots &&
      (userData.before?.f1_rolling_days || 2) < 2 &&
      userData.after.f1_rolling_days >= 2;
  }

  return {
    global: {
      isDemo,
      shouldWellness,
      shouldOnboard,
    },

    f1: {
      shouldListen,
      shouldRecord,
      hasMissedASession,
      hasPassedMorningSession,
      isFlowComplete,

      notifications: {
        hasMissedDays,
        hasHadConsecutiveDays,
      },
    },

    f2: {
      isUnlocked: hasUnlockedF2,

      notifications: {
        isUnlockingF2,
        hasMissedMeditations,
        hasNotStartedMeditations,
      },
    },

    f3: {
      isUnlocked: hasUnlockedF3,
      canWater: f3CanWater,

      notifications: {
        isUnlockingF3,
        /** @deprecated moved to {@link @backend/utils/find-checkins} */
        shouldCheckin: false,
      },
    },
  } as const;
}
