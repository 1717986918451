import { colors } from '@web/features/theme';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { classNames, defaultClassNames } from './swal.styles';

/**
 * TILTSwal
 * @packageDescription
 *
 * re-export of SWAL w/ React wrapper
 *
 * @see https://sweetalert2.github.io/
 */

const TILTMixin = Swal.mixin({
  position: 'center',
  confirmButtonColor: colors.info,
  allowOutsideClick: false,
  customClass: defaultClassNames,
});

const ToastMixin = Swal.mixin({
  toast: true,
  position: 'top-end',
  customClass: { popup: classNames.popup.toast },
  showConfirmButton: false,
  timer: 3500,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export const TILTSwal = withReactContent(TILTMixin);
export const Toast = withReactContent(ToastMixin);
