import { TILTTheme } from '../theme.types';
import { colors } from './colors';
import { typography } from './typography';
import { variants } from './variants';

export * from './animations';
export * from './colors';
export * from './typography';
export * from './variants';

export const theme: TILTTheme = {
  colors,
  ...typography,
  ...variants,
};
