/* eslint-disable no-console */
import { isProduction } from '@core/utils/env';
import * as React from 'react';

export function useServiceWorker() {
  React.useEffect(() => {
    if (typeof window !== undefined) {
      if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
          navigator.serviceWorker
            .register('/service-worker.js', {
              scope: '/',
            })
            .then((registration) => {
              if (!isProduction()) {
                console.info('SW: registered -> ', registration);
              }
            })
            .catch((registrationError) => {
              if (!isProduction()) {
                console.info('SW: failed -> ', registrationError);
              }
            });
        });
      }
    }
  }, []);
}
