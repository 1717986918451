export type Community = {
  id: string;
  name: string;
};

export type TMUser = {
  id: string;
  email: string;
  emailVerified: boolean;
  communities: Community[];
};

export function TMUserFromAPI(obj: any): TMUser {
  return {
    id: obj.id,
    email: obj.email,
    emailVerified: obj.email_verified,
    communities: obj.communities || [],
  };
}
