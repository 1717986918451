import { AuthClaims, AuthRoles } from '@core/auth';
import { TMUser } from './user';

export enum AuthStatus {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  ERROR = 'ERROR',
}

export interface AuthState {
  status: AuthStatus;
  isAuthenticated: boolean;
  user?: TMUser;
  error?: Error;
}

export enum AuthTypes {
  ON_AUTH_FETCH = 'ON_AUTH_FETCH',
  ON_AUTH_FULFILLED = 'ON_AUTH_FULFILLED',
  ON_AUTH_RESET = 'ON_AUTH_RESET',
  ON_AUTH_ERROR = 'ON_AUTH_ERROR',
}

export type AuthPayloads =
  | {
      user?: TMUser | null;
      claims?: AuthClaims;
      error?: Error;
    }
  | undefined;

export const initialAuthState = {
  status: AuthStatus.IDLE,
  isAuthenticated: false,
};

/**
 * # authReducer
 *
 * Reduces the state of the {@link AuthProvider} based on the previous state and action giveen
 *
 * @export
 * @param {AuthState} state
 * @param {{ type: AuthTypes; payload?: AuthPayloads }} action
 * @return {AuthState}  {AuthState}
 */
export function authReducer(
  state: AuthState,
  action: { type: AuthTypes; payload?: AuthPayloads },
): AuthState {
  const { type, payload } = action;

  switch (type) {
    case 'ON_AUTH_FETCH': {
      return {
        status: AuthStatus.PENDING,
        isAuthenticated: false,
      };
    }

    case 'ON_AUTH_FULFILLED': {
      const { user } = payload || {};

      if (typeof user === 'undefined' || user == null) {
        return { status: AuthStatus.FULFILLED, isAuthenticated: false };
      }

      return {
        status: AuthStatus.FULFILLED,
        isAuthenticated: !!user.id,
        user,
      };
    }

    case 'ON_AUTH_ERROR': {
      const { error } = payload || {};

      if (typeof error === 'undefined') {
        return {
          ...state,
          status: AuthStatus.ERROR,
          isAuthenticated: false,
          error: new Error(
            'Invalid Error. Please pass an error to `ON_AUTH_ERROR`',
          ),
        };
      }

      return {
        ...state,
        status: AuthStatus.ERROR,
        isAuthenticated: !!state.user?.uid,
        error,
      };
    }

    case 'ON_AUTH_RESET': {
      return initialAuthState;
    }

    default: {
      return state;
    }
  }
}
