'use client';

import { TMApi } from '@web/services/tmapi';
import { useIsRunningAsIOSApp } from '@web/utils/ios-app';
import { useCallback, useEffect } from 'react';

export default function useAppBridge() {
  const [isRunningAsIOSApp] = useIsRunningAsIOSApp();

  // requestNotificationToken will ask for push notificiation permission if the app is running in a container context
  // registerNotificationToken will be called by the native app if it's been granted
  const requestNotificationToken = async () => {
    call('requestPushNotificationPermission', {});
  };

  const notifyAppLoaded = async () => {
    call('notifyAppLoaded', {});
  };

  const notifyUserAuthenticated = useCallback(async () => {
    call('notifyUserAuthenticated', {});
  }, []);

  const call = async (method: string, payload: any) => {
    console.debug(`calling ${method}`);
    // assumes callContainer was injected by the containing app
    if (!globalThis.callContainer) {
      console.log(
        'callContainer not found. Code is probably not embedded in a container.',
      );
      return;
    }
    globalThis.callContainer(method, payload);
  };

  const handleRegisterNotificationToken = async (payload: {
    deviceToken: string;
    system: string;
  }) => {
    const tmapi = new TMApi();
    await tmapi.registerNotificationToken(payload.deviceToken, payload.system);
  };

  useEffect(() => {
    if (!isRunningAsIOSApp || globalThis.registerJSHandler === undefined) {
      return;
    }
    if (globalThis.containerHandlerRegistered) {
      return;
    }
    globalThis.containerHandlerRegistered = true;

    globalThis.registerJSHandler(
      'registerNotificationToken',
      handleRegisterNotificationToken,
    );
  });

  return { requestNotificationToken, notifyAppLoaded, notifyUserAuthenticated };
}
