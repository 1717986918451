// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';
import SentryRRWeb from '@sentry/rrweb';
import { BrowserTracing } from '@sentry/tracing';
import type { Integration } from '@sentry/types';

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENV =
  process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_APP_NAME || 'development';

const SESSION_REPLAY_IS_ENABLED = isSessionReplayEnabled();

const integrations: Array<Integration> = [];
if (SESSION_REPLAY_IS_ENABLED) {
  integrations.push(
    new SentryRRWeb({
      // checkoutEveryNms: 15 * 60 * 100,
      // checkoutEveryNth: 100,
      maskAllInputs: true,
    }),
  );
}

Sentry.init({
  enabled: true,
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  // Adjust this value in production, or use tracesSampler for greater control
  //  Currently having issues with the integration so I want everything traced,
  //    Hence, the 1 (for 100%, or all traces) below.
  // * Adjust this as needed for production
  tracesSampleRate: 1,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  integrations: (defaults) =>
    // defaults.concat(new BrowserTracing()).concat(integrations),
    defaults.concat(new BrowserTracing()),
});

Sentry.setTag('rrweb.active', SESSION_REPLAY_IS_ENABLED ? 'yes' : 'no');

function isSessionReplayEnabled(storageKey = 'SESSION_REPLAY_IS_ENABLED') {
  if (typeof window === 'undefined') {
    return false;
  }

  if (Boolean(window) && Boolean(localStorage)) {
    const value = localStorage.getItem(storageKey);

    if (value) {
      // eslint-disable-next-line no-console
      console.warn('[Sentry] Enabling Session Replay');
      return Boolean(value);
    }
  }

  return false;
}
