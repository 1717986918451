import { CommunityWaterLevels } from './hopes';
import { Metadata } from './metadata';
import { WellnessCheckValues } from './wellness';

export type ActivityName =
  | AuthActivityNames
  | AdminActivityNames
  | F1ActivityNames
  | F2ActivityNames
  | F3ActivityNames;

export type ActivityConfig =
  | AuthActivityConfig
  | AdminActivityConfig
  | F1ActivityActivityConfig
  | F2ActivityActivityConfig
  | F3ActivityActivityConfig;

export type Activity = Metadata & ActivityConfig;

export enum AuthActivityNames {
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign-up',
  SIGN_OUT = 'sign-out',
  VERIFY_EMAIL = 'verify-email',
}

export enum AdminActivityNames {
  /** Internals */
  CREATE_ADMIN = 'create-admin',
  MIGRATE_USERS = 'migrate-users',
  MIGRATE_RECORDING = 'migrate-recordings',
  MEGA_EXPORT = 'mega-export',

  /** Manage F2 Public Resource */
  CREATE_PUBLIC_CATEGORY = 'create-public-category',
  CREATE_PUBLIC_MEDITATION = 'create-public-medition',
  CREATE_PUBLIC_AVATAR = 'create-public-avatar',
  CREATE_PUBLIC_RESOURCE = 'create-public-resource',

  /** Manage F3 Public Resource */
  APPROVE_REPORTED_HOPE = 'approved-reported-hope',
  REJECT_REPORTED_HOPE = 'rejected-reported-hope',
}

export enum F1ActivityNames {
  ONBOARD = 'onboard',
  LISTEN = 'listen',
  RECORD = 'record',
  WELLNESS = 'wellness',
}

export enum F2ActivityNames {
  CREATE_CATEGORY = 'create-category',
  CREATE_MEDITATION = 'create-medition',
  CREATE_AVATAR = 'create-avatar',
  CREATE_RESOURCE = 'create-resource',
  LISTEN_MEDITATION = 'listen-medition',
}

export enum F3ActivityNames {
  CREATE_HOPE = 'create-hope',
  HARVEST_HOPE = 'harvest-hope',
  UPDATE_HOPE_AVATAR = 'update-hope-avatar',
  CHECKIN_HOPE = 'checkin-hope',
  COMPLETE_HOPE = 'complete-hope',
  REPORT_HOPE = 'report-hope',
  MUTE_HOPE = 'mute-hope',
  MUTE_USER = 'mute-user',
  COMMUNITY_WATERING = 'community-watering',
}

export type AuthActivityConfig =
  | { name: AuthActivityNames.SIGN_UP }
  | { name: AuthActivityNames.SIGN_OUT }
  | { name: AuthActivityNames.VERIFY_EMAIL }
  | {
      name: AuthActivityNames.SIGN_IN;
      details: {
        user_agent: string | null;
        platform: string | null;
        device: string | null;
        timezone_offset: number | null;
      };
    };

export type AdminActivityConfig =
  | {
      name: AdminActivityNames.CREATE_ADMIN;
      details: { uid: string; email: string };
    }
  | {
      name: AdminActivityNames.CREATE_PUBLIC_AVATAR;
      details: { avatarId: string };
    }
  | {
      name: AdminActivityNames.CREATE_PUBLIC_MEDITATION;
      details: { meditationId: string };
    }
  | {
      name: AdminActivityNames.CREATE_PUBLIC_RESOURCE;
      details: { resourceId: string };
    }
  | {
      name: AdminActivityNames.APPROVE_REPORTED_HOPE;
      details: { hopeId: string };
    }
  | {
      name: AdminActivityNames.REJECT_REPORTED_HOPE;
      details: { hopeId: string };
    };

export type F1ActivityActivityConfig =
  | { name: F1ActivityNames.ONBOARD }
  | {
      name: F1ActivityNames.LISTEN;
      details: {
        recordingId: string;
        shouldSave: boolean;
        name: string | null;
      };
    }
  | {
      name: F1ActivityNames.RECORD;
      details: { recordingId: string };
    }
  | {
      name: F1ActivityNames.WELLNESS;
      details: { wellnessId: string; values: WellnessCheckValues };
    };

export type F2ActivityActivityConfig =
  | {
      name: F2ActivityNames.CREATE_AVATAR;
      details: { avatarId: string };
    }
  | {
      name: F2ActivityNames.CREATE_MEDITATION;
      details: { meditationId: string };
    }
  | {
      name: F2ActivityNames.CREATE_RESOURCE;
      details: { resourceId: string };
    }
  | {
      name: F2ActivityNames.LISTEN_MEDITATION;
      details: { meditationId: string };
    };

export type F3ActivityActivityConfig =
  | {
      name: F3ActivityNames.CREATE_HOPE;
      details: { hopeId: string };
    }
  | {
      name: F3ActivityNames.UPDATE_HOPE_AVATAR;
      details: { hopeId: string; avatar_id: string };
    }
  | {
      name: F3ActivityNames.CHECKIN_HOPE;
      details: { hopeId: string; progress: number };
    }
  | {
      name: F3ActivityNames.COMPLETE_HOPE;
      details: { hopeId: string; progress: 100 };
    }
  | {
      name: F3ActivityNames.REPORT_HOPE;
      details: { hopeId: string };
    }
  | {
      name: F3ActivityNames.MUTE_HOPE;
      details: { hopeId: string };
    }
  | {
      name: F3ActivityNames.MUTE_USER;
      details: { uid: string };
    }
  | {
      name: F3ActivityNames.COMMUNITY_WATERING;
      details: { currentLevel: CommunityWaterLevels };
    }
  | {
      name: F3ActivityNames.HARVEST_HOPE;
      details: { hopeId: string };
    };
