import type * as firebase from 'firebase';

export type Metadata = {
  snapshotVersion: number;
  created_by: string;
  created_on: string;
  updated_by: string;
  updated_on?: firebase.default.firestore.FieldValue;
};

export type CreateMetadataConfig = {
  uid: string;
  lastActive: number | string;
};

export type UploadMetadata = firebase.default.storage.UploadMetadata &
  Partial<firebase.default.storage.FullMetadata> & { bucket: string };

export type CustomUploadMetadata = {
  collection: string;
  id: string;
  type: string;
};

export function createMetadata({
  uid,
  lastActive,
}: CreateMetadataConfig): Omit<Metadata, 'updated_on'> {
  return {
    snapshotVersion: 0,
    created_by: uid,
    created_on: new Date(lastActive).toISOString(),
    updated_by: uid,
  };
}
