export class CodedError extends Error {
  code: string;

  constructor(message: string, code: string) {
    super(message);
    this.name = this.constructor.name;
    this.code = code;

    Object.setPrototypeOf(this, CodedError.prototype);
  }
}
