/**
 * # addDays
 * A function that return a date + days at 5am.
 *
 *
 * @param daysInBetween - days to add to the date given
 * @param date - date to have days added
 */
export function addDays(daysInBetween = 0, date: number | string) {
  const nowDate = new Date(date);

  nowDate.setDate(nowDate.getDate() + daysInBetween);
  nowDate.setHours(5); // 1am
  nowDate.setMinutes(0); // reset minutes
  nowDate.setSeconds(0); // reset seconds

  return nowDate;
}
