export const typography = {
  fonts: {
    heading: `Ubuntu, -apple-system-headline, -apple-system, BlinkMacSystemFont, system-ui, "SF Pro Display", sans-serif, "Segoe UI", "Apple Color Emoji"`,
    body: `Ubuntu, -apple-system-body, -apple-system, BlinkMacSystemFont, system-ui, "SF Pro Display", sans-serif, "Segoe UI", "Apple Color Emoji"`,
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeights: {
    normal: 'normal',
    none: '1',
    shorter: '1.25',
    short: '1.375',
    base: '1.5',
    tall: '1.625',
    taller: '2',
  },
  fontWeights: {
    light: 200,
    normal: 400,
    semibold: 700,
    bold: 900,
  },
  fontSizes: {
    '2xs': '0.5rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
  },
} as const;
