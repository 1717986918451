import { userStatusReducer } from '@core/users/user-status-reducer';
import { UserData, UserStatus } from '@core/users/users';

export enum UserStateStatus {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  ERROR = 'ERROR',
}

export interface UserState {
  status: UserStateStatus;
  retries: number;
  error?: Error;
  overrideUid?: string;
  userData?: UserData;
  userStatus?: UserStatus;
  shouldHope?: boolean;
}

export enum UserDispatchTypes {
  ON_USER_FETCH = 'ON_USER_FETCH',
  ON_USER_FULFILLED = 'ON_USER_FULFILLED',
  ON_USER_RESET = 'ON_USER_RESET',
  ON_USER_ERROR = 'ON_USER_ERROR',
  ON_LOGIN_AS_USER = 'ON_LOGIN_AS_USER',
}

export type UserPayloads =
  | {
    userData?: UserData;
    error?: never;
    overrideUid?: never;
  }
  | {
    userData?: never;
    error: Error;
    overrideUid?: never;
  }
  | {
    userData?: never;
    error?: never;
    overrideUid: string;
  }
  | undefined;

export const initialUserState: UserState = {
  status: UserStateStatus.IDLE,
  retries: 0,
};

export function userDataReducer(
  state: UserState,
  action: { type: UserDispatchTypes; payload?: UserPayloads },
): UserState {
  const { type, payload } = action;

  switch (type) {
    case UserDispatchTypes.ON_USER_FETCH: {
      return {
        status: UserStateStatus.PENDING,
        overrideUid: state.overrideUid,
        retries: state.retries,
      };
    }

    case UserDispatchTypes.ON_USER_FULFILLED: {
      const { userData } = payload || {};

      if (typeof userData === 'undefined') {
        return { status: UserStateStatus.FULFILLED, retries: 0 };
      }

      return {
        status: UserStateStatus.FULFILLED,
        retries: 0,
        overrideUid: state.overrideUid,
        userData,
        userStatus: userStatusReducer({
          before: state.userData,
          after: userData,
        }),
      };
    }

    case UserDispatchTypes.ON_USER_ERROR: {
      const { error } = payload || {};

      return {
        ...state,
        status: UserStateStatus.ERROR,
        retries: state.retries + 1,
        error: error || new Error('Something went wrong getting user data.'),
      };
    }

    case UserDispatchTypes.ON_LOGIN_AS_USER: {
      const { overrideUid } = payload || {};

      if (!overrideUid) {
        return {
          ...state,
          status: UserStateStatus.ERROR,
          retries: state.retries + 1,
          error: new Error('No OverrideUid provided'),
        };
      }

      return {
        overrideUid,
        ...initialUserState,
      };
    }

    case UserDispatchTypes.ON_USER_RESET: {
      return initialUserState;
    }

    default: {
      return state;
    }
  }
}
