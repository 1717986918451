/**
 * FirebaseUser
 *
 * Our Version of a firebase.User before serialization
 */
export interface FirebaseUser extends firebase.default.User {
  email_verified?: boolean; // firebase admin sdk
}

export type AuthRoles = typeof AuthRoles[keyof typeof AuthRoles];
export const AuthRoles = {
  ADMIN: 'ADMIN',
  USER: 'USER',
} as const;

export type AuthClaims = {
  role?: AuthRoles;
  auth_time: number;
  email: string;
  user_id: string;
  email_verified: boolean;
};

export type CreateAdminConfig = { uid: string; email: string };

/**
 * AuthUserConfig
 * Used during {@link sign-up} or {@link sign-in} to as arguments to the
 *
 * @param isNewUser determines whether a user is signing up
 *    or signing in
 *
 * @param shouldRemember determines the auth persistence level
 *    if true -> 'local' | only the tab or until closed
 *    if false -> 'session' | the current session or until a user logs out
 */
export interface AuthUserConfig {
  isNewUser?: boolean;
  shouldRemember?: boolean;
}
